<script>
import appConfig from "@/app.config";
import Multiselect from 'vue-multiselect'
import Swal from "sweetalert2";
// import Switches from "vue-switches";
import Rooms from "./rooms";
import {getDropDownElementsByTypeApi,fetchProductsApi} from "@/api/common";
const dt = new Date;
const now = dt.getFullYear()+"-"+(dt.getMonth()+1 < 10 ? "0"+(dt.getMonth()+1): dt.getMonth()+1)+"-"+(dt.getDate()<10 ? "0"+dt.getDate() : dt.getDate()) ;

export default {
    page: {
        title: "Contrôle & Validation ODS Autres Services",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: {/*Switches,*/Rooms,Multiselect},
    props:{
      ODS:{
        type:Array,
        default:undefined
      },
      leftAmountContract:{}
    },
    data() {
      return {
      tableData: [],
      title: "Ordre de Service",
      selectedBdv:0,
      addPersonel:false,
      blocked:true,
      ODS_info: {
        ID_ODS:'',
        Bdv:0,
        ID_CTT:'',
        prestataire:0,
        date_ODS:'',
        date_exec_ODS:'',
        services:[],
        comments:''
      },
      nows:now,
      
      switchers:[],
      bases:[],
      prests:[],
          
      fields:[{selctedPoste:"",Qte:""}],
      selectedContract:[],
      contracts:[],
      RepasService:[],
      selectedPrest:[],
      disabled:false,
      gs_services:[],
      selectedServices:[],
      items:[],
      planB:[],
      filtredRepas:[],
      estimated:"",
      send:false
    };
  },
  watch:{
      ODS:{
      handler(val){
        // console.log(val);
        if(val[0] == undefined) {this.resetData(); this.have = false}
        else {
          this.ODS_info.id = val[0].id;
          this.ODS_info.ref = val[0].ref;
          this.ODS_info.type_ODS = val[0].type_ODS;
          this.ODS_info.Bdv = val[0].Bdv;
          this.ODS_info.ID_CTT = val[0].contract.id;
          this.ODS_info.prestataire = val[0].service_provider.id;
          this.ODS_info.date_ODS = val[0].date_ODS;
          this.ODS_info.date_exec_ODS = val[0].date_exec_ODS;
          
          this.ODS_info.period = "";
          this.ODS_info.read_at = val[0].read_at;
          this.ODS_info.done = val[0].done;
          this.ODS_info.verified = val[0].verified;
          this.ODS_info.status = val[0].status;
          this.ODS_info.lifebase = val[0].lifebase
          this.ODS_info.total_amount = val[0].totalAmnt.plain
          this.ODS_info.items = val[0].items
          // this.RepasService = Object.keys(val[0].items);
          this.filtredRepas=val[0].items;
          this.gs_services.push(this.filtredRepas[0].servicable.service);
          this.selectedServices.push(this.filtredRepas[0].servicable.service);
          // this.Repas = val[0].items
          
          this.selectedContract = val[0].contract;
          this.contracts.push(val[0].contract)
          this.selectedBdv = val[0].lifebase;
          this.bases.push(this.selectedBdv);
          this.selectedPrest = val[0].service_provider;
          // this.prestataire.push(val[0].service_provider);

          this.filtredRepas.forEach((element,i) => {
              this.items[i] = undefined;
          })

          this.$emit('getContractID',val[0].contract);
        }
        
      }
    },
    getEmit:{
      handler(val){
        this.getTotalAmountODS();
      }
    }
  },
  mounted(){
    
  },

  methods:{
    onComplete(){
      // this.getTotalAmountODS();
      Swal.fire({
            title: 'Êtes vous sûr ?',
            text: 'de vouloir valider le contrôle de cet ODS ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2b8a3e',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Oui',
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$http.post('/ods/OdsRestauration/validation',{
                  ods:this.ODS_info, items:this.items
              })
              .then(response => {
                  this.send = true;
                  this.$emit('odsvalues',true);
                  Swal.fire("Félicitations!", "Commande Contrôlé et Validé avec succées", "success");
                  this.resetData();
                  this.send=false;
              
              })
              .catch(error => console.log(error))
            }
          })
      
      
    },
    TodayDate(){
        var td, dt = new Date;
        td = dt.getFullYear()+"-"+(dt.getMonth()+1 < 10 ? "0"+(dt.getMonth()+1): dt.getMonth()+1)+"-"+(dt.getDate()<10 ? "0"+dt.getDate() : dt.getDate()) ;

        return td;
    },
    resetData(){
        this.ODS_info = {
        type_ODS:'green_space',
        status:'draft',
        Bdv:0,
        ID_CTT:0,
        prestataire:0,
        date_ODS:now,
        date_exec_ODS:now,
        services:[],
        comments:''
      };
      this.selectedContract=[];
      this.selectedBdv=[];
      this.selectedPrest=[];
      this.RepasService = [];
      this.filtredRepas = [];
      this.items = [];
      this.selectedServices=[];
    },
    
    getTotalAmountODS(){
      console.log(this.items);
      var total = 0
          this.items.forEach( x => {
            // console.log(parseInt(x.poste.unit_price)* parseInt(x.checkQte));
            total += ( parseInt(x.service.unit_price)* parseInt(x.checkQte) )
          })
      console.log(this.leftAmountContract);
      if(total > this.leftAmountContract){
          Swal.fire(
            'Dépassement',
            `Vous avez dépassé le montant restant du contrat de: ${new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(Math.abs(this.leftAmountContract - total))}`,
            'error'
          )
          // this.blocked = true;
      }  
      else{
        Swal.fire(
            'Correct',
            `Montant Restant du contract après validation: ${new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(this.leftAmountContract - total)}`,
            'success'
          )
          this.blocked=false
      }
      // console.log(total);
      this.getEmit=false;
      this.ODS_info.total_amount_verified = total;
      this.$emit('odsvalues',false);
      return total;
    }
  }
    
}
</script>

<template>
    <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-12">
        <!-- <b-tabs content-class="mt-0">
          <b-tab title="Informations Générales" active> -->
                <div class="">
                <div class="">
                  <!-- <h4 class="card-title">New </h4> -->
                  
                    <div class="p-2">
                          <div>
                            <fieldset :disabled="!disabled">
                                <!-- <b-alert show variant="info"> <i class="mdi mdi-information-outline"></i> Les champs obligatoires sont marqués avec (*) </b-alert> -->
                                <div class="row">
                                      <div class="col-lg-4">
                                        <div class="form-group">
                                          <label for="formrow-password-input">N° Contrat</label>
                                          <multiselect v-model="selectedContract" track-by="id" label="designation" placeholder="" :options="contracts" :searchable="false" :allow-empty="false">
                                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.designation }}</strong></template>
                                          </multiselect>
                                          
                                        </div>
                                      </div>

                                      <div class="col-lg-4">
                                        <div class="form-group">
                                          <label for="formrow-inputCity"
                                            >Nom de la Base *</label
                                          >
                                          <multiselect v-model="selectedBdv"  :disabled="bases.length == 1" :value="ODS_info.Bdv" track-by="id" label="name" placeholder="" :options="bases" :searchable="false" :allow-empty="false">
                                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                                          </multiselect>
                                          
                                        </div>
                                      </div>

                                      <div class="col-lg-4">
                                          <label for="">Prestataire *</label>
                                          <multiselect v-model="selectedPrest" :disabled="true" :value="ODS_info.prestataire" track-by="id" label="prestname" placeholder="" :options="prests" :searchable="false" :allow-empty="false">
                                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.prestname }}</strong></template>
                                          </multiselect>
                                          
                                      </div>
                                  </div>
                                  
                                  <div class="row">
                                      <div class="col-lg-4">
                                          <label for="">Date ODS</label>
                                          <input type="date" class="form-control" v-model="ODS_info.date_ODS" >
                                          
                                      </div>
                                      <div class="col-lg-4">
                                          <label for="">Date execution</label>
                                          <input type="date" class="form-control" v-model="ODS_info.date_exec_ODS">
                                          
                                      </div>
                                      <div class="col-lg-4">
                                          <label for="">Type de Service *</label>
                                          

                                          <multiselect v-model="selectedServices" :deselect-label="''" :disabled="!disabled" :multiple="true"  track-by="id" label="name" placeholder="" :options="gs_services" :searchable="false" :allow-empty="false">
                                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.designation }}</strong></template>
                                          </multiselect>
                                      </div>
                                  </div>
                                  

                                  <div class="row mt-2">
                                      <div class="col-md-12">
                                        <div class="form-group">
                                          <label for="formrow-inputCity">Commentaires</label>
                                          <textarea
                                            v-model="ODS_info.comments"
                                            class="form-control"
                                            cols="30"
                                            rows="1" 
                                            required
                                          ></textarea>
                                        </div>
                                      </div>
                                  </div>

                                <!-- end row -->
                                </fieldset>
                          </div>
                          <!-- end card-body -->
                    </div>

                    <div class="p-3">
                      <!-- <resources/> -->
                      <div class="row mt-2" v-for="(rep,i) in filtredRepas" :key="i">
                        <div class="col">
                          <rooms
                              :disable="disabled" 
                              :key="i" 
                              :pos="i" 
                              :service="rep" 
                              @getvalues="items[`${i}`] = $event" 
                              @getvaluesEmitted="getEmit = $event" />
                        </div>
                        
                      </div>
                    </div>

                    <div class="row">
                      <div class="col text-right mr-2 mb-3" v-if="ODS_info.id">
                        <b-button :disabled="ODS_info.verified == 1" @click="getTotalAmountODS" variant="info" class="btn-label mr-2">
                            <i class="bx bx-check label-icon"></i> Vérifier
                        </b-button>
                        <b-button :disabled="ODS_info.verified == 1 ||blocked" @click="onComplete" variant="primary" class="btn-label mr-2">
                            <i class="bx bx-check-double label-icon"></i> Enregitrer & Valider
                        </b-button>
                        
                      </div>
                    </div>

                    
                  
                </div>
                <!-- end card-body -->
              </div>
        
      </div>
    </div>
    <!-- </Layout> -->
</template>
