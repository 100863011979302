<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
// import recepForm from "./components/recepForm";
import RecepForm from './components/RecepForm';
import Dataset from 'vue-dataset/dist/es/Dataset.js'
import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
// import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
// import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js'

export default {
    page: {
        title: "Base de vies de l'entreprise",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: {
      Layout, PageHeader,RecepForm,
      Dataset,
      DatasetItem,
      // DatasetInfo,
      DatasetPager,
      // DatasetSearch,
      DatasetShow,
    },
    data() {

            return {
      tableData: [],
      title: "Contrôle & Validation",
      List_ODS: [],
      leftAmount:0,
      active:false,
      selectedRows:[],
      ODS:undefined,
      ArchivedODS:[],
      InProgressODS:[],
      items: [
        {
          text: "Catalogue des Services",
          to: {name:"service_catalog"},
        },
        {
          text: "Autres Services",
          to: {name:"service_catalog.custom_services"},
        },
        {
          text: "Contrôle & Validation",
          active: true,
        },
      ],
      cols: [
        {
          name: 'N° ODS',
          field: 'ref',
          sort: ''
        },
        {
          name: 'Base de vie',
          field: 'lifebase',
          sort: ''
        },
        {
          name: 'Contrat',
          field: 'contract',
          sort: ''
        },
        {
          name: 'Prestataire',
          field: 'supplier',
          sort: ''
        },
        {
          name: 'Date ODS',
          field: 'date_ods',
          sort: ''
        },
        {
          name: 'Date Execution',
          field: 'date_exec_ods',
          sort: ''
        },
        {
          name: 'Coût ODS',
          field: 'total_amount',
          sort: ''
        },
        {
          name: 'Statut',
          field: 'status',
          sort: ''
        },
        {
          name: 'Réaliser',
          field: 'done',
          sort: ''
        },
        {
          name: 'Vérifier',
          field: 'verified',
          sort: ''
        },
        {
          name: 'Montant Contrôle',
          field: 'total_amount_verified',
          sort: ''
        },
      ],
      selectedIndex:-1,
    };
  },
  mounted(){
    this.getODS();
  },
  watch:{
      // selectedRows(){
      //   console.log(this.selectedRows);
      // },
      ODS:{
          handler(val){
            console.log(val);
            if(val)this.refreshODS();
            
          }
          
          
      }
  },
  methods:{
    selectRow(row,index){
      if(this.selectedIndex == index) {this.selectedRows = []; this.selectedIndex = -1;}
      else {
        this.selectedRows = [row];
        this.selectedIndex = index;
      }
    },
    getODS(){
      this.$http.post('/ods/OdsRestauration/listResto',{type:'custom',verified:false})
      .then(response => {
          console.log(response.data);
          this.ArchivedODS = response.data.filter(x => (x.verified == 1));
          this.InProgressODS = response.data.filter(x => (x.verified == 0));
          this.List_ODS = response.data.filter(x => (x.verified == 0));
      })
      .catch(error => console.log(error))
    },
    refreshODS(){
      this.getODS();
      this.selectedRows = [];
        this.selectedIndex = -1;
    },
    ArchivedListODS(data){
      if(data == 'Archived') this.List_ODS = this.ArchivedODS;
      if(data == 'InProgress') this.List_ODS = this.InProgressODS;

    },
    leftAmountInContract(contract){
      // console.log(contract.camps_custom_services[0]);
      var selectedODS = this.ArchivedODS.filter(x => (x.contract.id == contract.id))
      if(selectedODS.length > 0){
          var total_ods = selectedODS.reduce(function(carry,item){
              return carry += parseInt(item.totalAmntVerified.plain);
          },0);
          
          var left = parseInt(contract.camps_custom_services[0].amount) - parseInt(total_ods);
          this.leftAmount = left;
      }
      else this.leftAmount = parseInt(contract.camps_custom_services[0].amount);

      console.log(this.leftAmount);
      
    }
  }
    
}
</script>
<template>
    <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste Ordres de Service (Autres Services)</div>
                  <div class="col text-right">
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-0" :visible="active" accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg">
              <div class="table-responsive mb-0 shadow">
                <dataset
                  v-slot="{ ds }"
                  :ds-data="List_ODS"
                >
                  <div class="row">
                    <div class="col-md-6 mb-2 mb-md-0">
                      
                    </div>
                    <div class="col-md-6 d-flex justify-content-end">
                      <div class="btn-group">
                          <button
                            type="button"
                            class="btn btn-outline-secondary"
                            @click="ArchivedListODS('Archived')"
                          >
                            <i class="fas fa-archive"></i> <span class="badge bg-success text-white">{{ ArchivedODS.length }}</span> Archives
                          </button>
                          <button
                            type="button"
                            class="btn btn-outline-secondary"
                            @click="ArchivedListODS('InProgress')"
                          >
                            <b-spinner
                              variant="warning"  small class="mb-1"
                            ></b-spinner>
                            <span class="badge bg-warning text-white ml-1"> {{ InProgressODS.length }}</span> En cours
                          </button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="">
                        <table class="table table-hover d-md-table">
                          <thead>
                            <tr>
                              <th v-for="(th) in cols" class="text-center" :key="th.field">
                                {{ th.name }} 
                              </th>
                            </tr>
                          </thead>
                          <dataset-item tag="tbody">
                            <template #default="{ row, rowIndex }">
                              <tr @click="selectRow(row,rowIndex)" :class="{'bg-soft-info':selectedIndex == rowIndex}" style="cursor:pointer">
                                <th class="text-center">
                                  {{ row.ref }}
                                </th>
                                <td class="text-center">{{ row.lifebase.name }}</td>
                                <td class="text-center">{{ row.contract.ref }}</td>
                                <td class="text-center">{{ row.service_provider.prestname }}</td>
                                <td class="text-center">{{ row.date_ODS_format }}</td>
                                <td class="text-center">{{ row.date_exec_ODS_format }}</td>
                                <td class="text-center">{{ row.totalAmnt.withCurrency }}</td>
                                <td class="text-center" v-html="row.status_label"></td>
                                
                                <td v-if="row.done == 1" class="text-center"><i class="fas fa-check-circle text-success"></i></td>
                                <td v-else class="text-center"><i class="fas fa-times-circle text-danger"></i></td>

                                <td v-if="row.verified == 1" class="text-center"><i class="fas fa-check-circle text-success"></i></td>
                                <td v-else class="text-center"><i class="fas fa-times-circle text-danger"></i></td>

                                <td v-if="row.verified == 1" class="text-center">{{ row.totalAmntVerified.withCurrency }}</td>
                                <td v-else class="text-center">-</td>
                              </tr>
                            </template>
                          </dataset-item>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                    <dataset-show :ds-show-entries="5" />
                    <dataset-pager />
                  </div>
                </dataset>
              </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

        <div class="card mt-3">
            <RecepForm  :ODS="selectedRows" @odsvalues="ODS = $event"  @getContractID ="leftAmountInContract($event)" :leftAmountContract="leftAmount" />
            <!-- <recep-form :ODS="selectedRows" @odsvalues="ODS = $event" /> -->
        </div>
      </div>
    </div>
    </Layout>
</template>